<template>
  <div>
    <v-scale-screen width="1920" height="1080">
      <div
        style="
          display: flex;
          color: white;
          position: absolute;
          font-size: 18px;
          width: 20vw;
          align-items: center;
          z-index: 999;
          right: 0%;
          top: 3%;
        "
      >
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[0] }}</div>
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[1] }}</div>
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[2] }}</div>
      </div>
      <el-carousel
        indicator-position="outside"
        :interval="15000"
        ref="carousel"
        @mouseenter.native="delHandleMouseEnter"
        autoplay
      >
        <el-carousel-item key="0">
          <div class="dashboard-box">
            <img
              src="../assets/img/biaoti.png"
              style="height: 80px; width: 100%"
            />
            <p class="biaoti">原材料仓库库存A区</p>
            <el-table
              :data="tableDataA"
              style="width: 100%"
              :span-method="handleSpan"
            >
              <el-table-column
                v-for="(item, key) in tableHead"
                :key="key"
                :label="item.label"
                :prop="item.prop"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-carousel-item>
        <el-carousel-item key="1">
          <div class="dashboard-box">
            <img
              src="../assets/img/biaoti.png"
              style="height: 80px; width: 100%"
            />
            <p class="biaoti">原材料仓库库存B区</p>
            <el-table
              :data="tableDataB"
              style="width: 100%"
              :span-method="handleSpanB"
            >
              <el-table-column
                v-for="(item, key) in tableHead"
                :key="key"
                :label="item.label"
                :prop="item.prop"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-carousel-item>
        <el-carousel-item key="2">
          <div class="dashboard-box">
            <img
              src="../assets/img/biaoti.png"
              style="height: 80px; width: 100%"
            />
            <p class="biaoti">原材料仓库库存C区</p>
            <el-table
              :data="tableDataC"
              style="width: 100%"
              :span-method="handleSpanC"
            >
              <el-table-column
                v-for="(item, key) in tableHead"
                :key="key"
                :label="item.label"
                :prop="item.prop"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-carousel-item>
        <el-carousel-item key="3">
          <div class="page1"></div>
        </el-carousel-item>
        <el-carousel-item key="4">
          <div class="page2"></div>
        </el-carousel-item>
        <el-carousel-item key="5">
          <div class="page3"></div>
        </el-carousel-item>
        <el-carousel-item key="6">
          <div class="page4"></div>
        </el-carousel-item>
        <!-- <el-carousel-item key="7">
          <div class="page5"></div>
        </el-carousel-item> -->
      </el-carousel>
    </v-scale-screen>
  </div>
</template>

<script>
import axios from 'axios'
import { formatDate } from '@/utils/formatTime'
require('echarts/theme/macarons')

export default {
  data () {
    return {
      tableHead: [
        {
          label: '区域',
          prop: 'area'
        },
        {
          label: '牌号',
          prop: 'brandno'
        },
        {
          label: '规格',
          prop: 'specifications'
        },
        {
          label: '钢厂',
          prop: 'steelmill'
        },
        {
          label: '炉号',
          prop: 'heatnumber'
        },
        {
          label: '重量',
          prop: 'weight'
        },
        {
          label: '来料时间',
          prop: 'addtime'
        }
      ],
      txt: [],
      tableDataA: [],
      tableDataB: [],
      tableDataC: [],
      today: '',
      time: {
        txt: [],
        fun: null
      }
    }
  },
  created () {
    this.getData()
    this.initTime()
  },
  filters: {
    // 数字超过100css字体变小
    formatfontsize (time) {
      return time.substring(0, 10)
    }
  },
  mounted () {
    setInterval(() => {
      this.getData()
    }, 60000)
    this.$refs.carousel.handleMouseEnter = () => { }
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      }
      return ''
    },
    delHandleMouseEnter () {
      this.$refs.carousel.handleMouseEnter = () => { }
    },
    handleSpan ({ row, column, rowIndex, columnIndex }) {
      // 判断是否是需要合并的列
      if (columnIndex === 0) { // 假设我们要合并的是第一列（姓名）
        // 获取前一行的数据
        const prevRow = this.tableDataA[rowIndex - 1]
        // 判断当前行和前一行的姓名是否相同
        if (prevRow && row.area === prevRow.area) {
          // 如果相同，设置当前行为0行跨度，不显示
          return { rowspan: 0, colspan: 1 }
        } else {
          // 否则，找到所有需要合并的行数
          let nextRow = this.tableDataA[rowIndex + 1]
          let rowspan = 1
          while (nextRow && row.area === nextRow.area) {
            nextRow = this.tableDataA[++rowspan + rowIndex]
          }
          // 返回合并范围
          return { rowspan, colspan: 1 }
        }
      }
      // 其他列不需要合并
      return { rowspan: 1, colspan: 1 }
    },
    handleSpanB ({ row, column, rowIndex, columnIndex }) {
      // 判断是否是需要合并的列
      if (columnIndex === 0) { // 假设我们要合并的是第一列（姓名）
        // 获取前一行的数据
        const prevRow = this.tableDataB[rowIndex - 1]
        // 判断当前行和前一行的姓名是否相同
        if (prevRow && row.area === prevRow.area) {
          // 如果相同，设置当前行为0行跨度，不显示
          return { rowspan: 0, colspan: 1 }
        } else {
          // 否则，找到所有需要合并的行数
          let nextRow = this.tableDataB[rowIndex + 1]
          let rowspan = 1
          while (nextRow && row.area === nextRow.area) {
            nextRow = this.tableDataB[++rowspan + rowIndex]
          }
          // 返回合并范围
          return { rowspan, colspan: 1 }
        }
      }
      // 其他列不需要合并
      return { rowspan: 1, colspan: 1 }
    },
    handleSpanC ({ row, column, rowIndex, columnIndex }) {
      // 判断是否是需要合并的列
      if (columnIndex === 0) { // 假设我们要合并的是第一列（姓名）
        // 获取前一行的数据
        const prevRow = this.tableDataC[rowIndex - 1]
        // 判断当前行和前一行的姓名是否相同
        if (prevRow && row.area === prevRow.area) {
          // 如果相同，设置当前行为0行跨度，不显示
          return { rowspan: 0, colspan: 1 }
        } else {
          // 否则，找到所有需要合并的行数
          let nextRow = this.tableDataC[rowIndex + 1]
          let rowspan = 1
          while (nextRow && row.area === nextRow.area) {
            nextRow = this.tableDataC[++rowspan + rowIndex]
          }
          // 返回合并范围
          return { rowspan, colspan: 1 }
        }
      }
      // 其他列不需要合并
      return { rowspan: 1, colspan: 1 }
    },
    // 初始化右上角时间显示
    initTime () {
      this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM WWW').split(' ')
      this.time.fun = setInterval(() => {
        this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM WWW').split(
          ' '
        )
      }, 1000)
    },
    daysSinceStart () {
      const startDate = new Date('2024-01-01') // 定义开始日期
      const currentDate = new Date() // 获取当前日期
      // 计算两个日期之间的差值（以毫秒为单位）
      const timeDifference = currentDate.getTime() - startDate.getTime()
      // 将毫秒转换为天数（1天 = 24小时 × 60分钟 × 60秒 × 1000毫秒）
      const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      return daysPassed
    },
    flatData (data) {
      const result = []
      const nullitem = { brandno: '', specificarea: '', steelmill: '', heatnumber: '', weight: '', way: '', addtime: '' }
      data.forEach(area => {
        if (area.datalist.length > 0) {
          area.datalist.forEach(item => {
            result.push({
              ...item,
              area: area.area
            })
          })
        } else {
          result.push({
            ...nullitem,
            area: area.area
          })
        }
      })
      return result
    },
    getData () {
      this.today = this.daysSinceStart()
      // axios.get('http://192.168.4.76:8011/api/Statistics/GetstockListFromIndex?area=A').then((res) => {
      axios.get('https://ailun.chuilian-tech.cn/api/Statistics/GetstockListFromIndex?area=A').then((res) => {
        this.tableDataA = this.flatData(res.data.data)
      })
        .catch((err) => {
          console.log(err)
        })
      // axios.get('http://192.168.4.76:8011/api/Statistics/GetstockListFromIndex?area=B').then((res) => {
      axios.get('https://ailun.chuilian-tech.cn/api/Statistics/GetstockListFromIndex?area=B').then((res) => {
        this.tableDataB = this.flatData(res.data.data)
      })
        .catch((err) => {
          console.log(err)
        })
      // axios.get('http://192.168.4.76:8011/api/Statistics/GetstockListFromIndex?area=C').then((res) => {
      axios.get('https://ailun.chuilian-tech.cn/api/Statistics/GetstockListFromIndex?area=C').then((res) => {
        this.tableDataC = this.flatData(res.data.data)
      })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "UnidreamLED";
  src: url("../assets/fontface/ds-digib-webfont.woff") format("woff"),
    local("UnidreamLED"), url("../assets/fontface/ds-digib-webfont.woff"); /***默认使用本地的***/
}
.dashboard-box {
  height: 100vh;
  width: 100vw;
  background: url("../assets/img/aldbbjt.png");
}
.biaoti {
  position: absolute;
  font-size: 2.75rem;
  top: -4%;
  right: 40%;
  color: white;
}
.main-body {
  display: flex;
  justify-content: space-between;
}
.left-body {
  width: 65%;
}
.right-body {
  width: 35%;
  padding: 0 10px;
}
.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  background: url("../assets/img/dibubiaoti.png") no-repeat;
  background-position: 50% 85%;
  div {
    color: #fff;
    position: absolute;
    left: 45%;
    bottom: 5%;
    font-size: 1.5rem;
  }
  .day {
    color: #1ce6f4;
  }
}
.statusbox {
  border: 1px solid #76ec2c;
  color: #76ec2c;
  width: 80px;
  margin: 0 auto;
  border-radius: 15px;
}
.check {
  border: 1px solid #fff;
  color: rgb(22, 28, 92);
  background: #fff;
  width: 88px;
  padding: 0 5px;
  border-radius: 15px;
}
.wrap-text {
  white-space: pre-line;
}
.uncheck {
  border: 1px solid #fff;
  color: #fff;
  width: 88px;
  padding: 0 5px;
  border-radius: 15px;
}
.box-title {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  height: 3vh;
  line-height: 3vh;
  background-position: -12% 100%;
  margin-bottom: 0.5vh;
}
.box-area {
  background-color: rgb(22 28 92 / 46%);
  border: 1px solid rgb(68, 85, 107);
  color: #fff;
  padding: 0.5vw;
  .area-title {
    margin: 1% 0;
    display: flex;
    align-content: center;
    img {
      margin-right: 1%;
    }
  }
  .box-item {
    display: flex;
    justify-content: space-between;
    background: url("../assets/img/biankaung1.png") no-repeat;
    background-size: 100% 100%;
    padding: 1% 2%;
  }
  .box-span {
    background: url("../assets/img/biankuang2.png") no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    font-size: 1.5vh;
    text-align: center;
    width: 7vh;
    p:first-child {
      height: 4vh;
      margin-block-start: 0;
      margin-block-end: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 0;
    }
  }
}
:deep() .el-carousel__container {
  height: 100vh !important;
}
.box-title-b {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  background-position: -2% 60%;
  margin-bottom: 0.5vh;
}
.page1 {
  background-image: url("../assets/img/page1.png");
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page2 {
  background: url("../assets/img/page2.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page3 {
  background: url("../assets/img/page3.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page4 {
  background: url("../assets/img/page4.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page5 {
  background: url("../assets/img/page5.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.box-title-c {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  background-position: -11% 55%;
  margin-bottom: 0.5vh;
}
.title {
  font-size: 2vh;
  margin-left: 1.5vw;
}
.title-tip {
  font-size: 1rem;
  margin-left: 0.5%;
  color: #1ce6f4;
}
.box1 {
  background: url("../assets/img/biankaung3.png") no-repeat center;
  background-size: 100% 100%;
  background-position: 100% 100%;
  width: 48%;
  min-height: 154px;
  margin: 1%;
  height: 11%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 2vh;
  font-family: "UnidreamLED";
  img {
    width: 2vh;
    padding: 1vh;
  }
}
.box-div {
  padding: 0 10px;
}
.left-num {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3vh;
}
.num-b {
  color: #1ce6f4;
  font-size: 5vh;
  font-weight: bold;
}

.num-g {
  color: #76ec2c;
  font-size: 5vh;
  font-weight: bold;
}

.num-r {
  color: #fd0717;
  font-size: 5vh;
  font-weight: bold;
}

.num-y {
  color: #ebb30b;
  font-size: 5vh;
  font-weight: bold;
}
.num-e {
  color: rgb(254, 232, 86);
  font-weight: bold;
}
::v-deep .el-table {
  background-color: transparent;
  color: white;
}
::v-deep .el-table thead {
  color: #1ce6f4 !important;
  background-color: rgb(66 136 232 / 46%);
  margin-bottom: 5px;
}
::v-deep .el-table tr {
  background-color: rgb(22 28 92 / 46%);
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border-top: 1px solid #4288e8;
  border-bottom: none;
}
::v-deep .el-table th > .cell {
  padding-left: 0px;
  padding-right: 0px;
}
::v-deep .el-table::before {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table .cell {
  padding: 2px 0;
}
::v-deep .el-table th {
  padding: 6px 0;
  background-color: transparent;
}
::v-deep.el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table td.el-table__cell {
  border: 1px solid #4288e8;
}
::v-deep .el-table td {
  padding: 3px 0;
}
#echarts-line {
  height: 30vh;
}
#echarts-pie {
  height: 30vh;
}
::v-deep .warning-row {
  background-color: rgba(1, 2, 14, 0.46) !important;
}
</style>
